import { BaseDto } from '@/shared/dtos/base-dto';

export class dieta_estadistica extends BaseDto {
    public energia !: string;
    public etanol !: string;
    public glucidos_totales !: string;
    public polisacaridos !: string;
    public azucares !: string;
    public fibra !: string;
    public grasas_totales !: string;
    public agmi !: string;
    public agpi !: string;
    public ags !: string;
    public colesterol !: string;
    public proteinas_totales !: string;
    public proteinas_animales !: string;
    public proteinas_vegetales !: string;
    public iodo !: string;
    public sodio !: string;
    public potasio !: string;
    public calcio !: string;
    public magnesio !: string;
    public fosforo !: string;
    public hierro !: string;
    public zinc !: string;
    public selenio !: string;
    public vit_b1 !: string;
    public vit_b2 !: string;
    public vit_b6 !: string;
    public vit_b12 !: string;
    public vit_folato !: string;
    public vit_niacina !: string;
    public vit_c !: string;
    public vit_a !: string;
    public vit_d !: string;
    public vit_e !: string;
    public energia_obj_nut !: string;
    public etanol_obj_nut !: string;
    public glucidos_totales_obj_nut !: string;
    public polisacaridos_obj_nut !: string;
    public azucares_obj_nut !: string;
    public fibra_obj_nut !: string;
    public grasas_totales_obj_nut !: string;
    public agmi_obj_nut !: string;
    public agpi_obj_nut !: string;
    public ags_obj_nut !: string;
    public colesterol_obj_nut !: string;
    public proteinas_totales_obj_nut !: string;
    public proteinas_animales_obj_nut !: string;
    public proteinas_vegetales_obj_nut !: string;
    public iodo_obj_nut !: string;
    public sodio_obj_nut !: string;
    public potasio_obj_nut !: string;
    public calcio_obj_nut !: string;
    public magnesio_obj_nut !: string;
    public fosforo_obj_nut !: string;
    public hierro_obj_nut !: string;
    public zinc_obj_nut !: string;
    public selenio_obj_nut !: string;
    public vit_b1_obj_nut !: string;
    public vit_b2_obj_nut !: string;
    public vit_b6_obj_nut !: string;
    public vit_b12_obj_nut !: string;
    public vit_folato_obj_nut !: string;
    public vit_niacina_obj_nut !: string;
    public vit_c_obj_nut !: string;
    public vit_a_obj_nut !: string;
    public vit_d_obj_nut !: string;
    public vit_e_obj_nut !: string;
    public energia_dif !: string;
    public etanol_dif !: string;
    public glucidos_totales_dif !: string;
    public polisacaridos_dif !: string;
    public azucares_dif !: string;
    public fibra_dif !: string;
    public grasas_totales_dif !: string;
    public agmi_dif !: string;
    public agpi_dif !: string;
    public ags_dif !: string;
    public colesterol_dif !: string;
    public proteinas_totales_dif !: string;
    public proteinas_animales_dif !: string;
    public proteinas_vegetales_dif !: string;
    public iodo_dif !: string;
    public sodio_dif !: string;
    public potasio_dif !: string;
    public calcio_dif !: string;
    public magnesio_dif !: string;
    public fosforo_dif !: string;
    public hierro_dif !: string;
    public zinc_dif !: string;
    public selenio_dif !: string;
    public vit_b1_dif !: string;
    public vit_b2_dif !: string;
    public vit_b6_dif !: string;
    public vit_b12_dif !: string;
    public vit_folato_dif !: string;
    public vit_niacina_dif !: string;
    public vit_c_dif !: string;
    public vit_a_dif !: string;
    public vit_d_dif !: string;
    public vit_e_dif !: string;

} 
