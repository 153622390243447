import { BaseDto } from '@/shared/dtos/base-dto';

export class dieta_visualizacion extends BaseDto {
    public id_dieta !: number;
    public id_dia!: number;
    public id_estructura !: number;
    public cantidad !: number;
    public id_comida !: number;
    public orden !: number;
    public nombre !: string;
    public nombre_comida !: string;
    public id_tipo_dato !: number;
} 
