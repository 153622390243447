import { store } from '@/store/store';
import { dieta_visualizacion } from '@/shared/dtos/visualizacion_dieta/dieta_visualizacion';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';
import { dieta_estadistica } from '@/shared/dtos/visualizacion_dieta/dieta_estadistica';
import { UtilsFiles } from '@/utils/utils-files';
import { dietaDto } from '@/shared/dtos/visualizacion_dieta/dietaDto';
import { SendDataEntrevistaDto } from '@/shared/dtos/visualizacion_dieta/entrevista/SendDataEntrevistaDto';
import { dietaAllDto } from '@/shared/dtos/visualizacion_dieta/dietAllDto';
import { BaseDto } from '@/shared/dtos/base-dto';
import { AltasDepacientes } from '@/shared/dtos/DashboardsDtos/AltasDepacientes';
import { dieta_dashboard } from '@/shared/dtos/visualizacion_dieta/Dashboards/dieta_dashboard';

@Module({
    namespaced: true,
    name: 'dieta_visualizacionModule',
    store,
    dynamic: true
})
class dieta_visualizacionModule extends VuexModule {
    public dieta_visualizacions: any[][] = [];
    public estadistica: dieta_estadistica = new dieta_estadistica();
    public datos_dieta: dietaDto = new dietaDto();
    public dietas: dietaAllDto[] = [];
    public dash_historico: AltasDepacientes = new AltasDepacientes();
    public dash_dieta: dieta_dashboard = new dieta_dashboard();
    public datos_para_estadisticas = {
        dias: [{ value: "Lunes", key: 1, cheked: true },
        {
            value: "Martes",
            key: 2,
            cheked: true,
        },
        {
            value: "Miércoles",
            key: 3,
            cheked: true,
        },
        {
            value: "Jueves",
            key: 4,
            cheked: true,
        },
        {
            value: "Viernes",
            key: 5,
            cheked: true,
        },
        {
            value: "Sábado",
            key: 6,
            cheked: true,
        },
        {
            value: "Domingo",
            key: 7,
            cheked: true,
        },], comidas: [{
            value: "Desayuno",
            key: 1,
            cheked: true,
        },
        {
            value: "Tentempié 1",
            key: 2,
            cheked: true,
        },
        {
            value: "Tentempié 2",
            key: 3,
            cheked: true,
        },
        {
            value: "Comida",
            key: 4,
            cheked: true,
        },
        {
            value: "Merienda 1",
            key: 5,
            cheked: true,
        },
        {
            value: "Merienda 2",
            key: 6,
            cheked: true,
        },
        { value: "Cena", key: 7, cheked: true },
        {
            value: "Recena 1",
            key: 8,
            cheked: true,
        },
        {
            value: "Recena 2",
            key: 9,
            cheked: true,
        },]
    };

    @Action({ commit: 'onGetdieta_visualizacions' })
    public async getdieta_visualizacions(id: number) {
        return await ssmHttpService.get(API.dieta + '/dieta/' + id);
    }
    @Action({ commit: 'onGetdieta_visualizacions' })
    public async getdieta_visualizacions_back(id: number) {
        return await ssmHttpService.get(API.dieta + '/dieta/' + id,null,false);
    }

    @Action({ commit: 'onGetIdDieta' })
    public async getdieta(id: number) {
        return await ssmHttpService.get(API.dieta + '/' + id);
    }

    @Action({ commit: 'onGetdietas_paciente' })
    public async getdietas_paciente(id_paciente: number) {
        return await ssmHttpService.get(API.dieta + '/dietas/' + id_paciente);
    }

    @Action({ commit: 'onGetIdDieta' })
    public async getIdDieta(id_paciente: number) {
        return await ssmHttpService.get(API.dieta + '/generar_id/' + id_paciente);
    }
    @Action({ commit: 'onGetIdDieta' })
    public async generarDietaAutomatica(opciones_dieta: SendDataEntrevistaDto) {
        return await ssmHttpService.post(API.dieta + '/generar_dieta_automatica', opciones_dieta.toJson());
    }
    @Action({ commit: 'onGetDashHistKcalDieta' })
    public async getDashHistKcalDieta(id_paciente: number) {
        return await ssmHttpService.get(API.dieta + '/dash_hist_kcal/' + id_paciente);
    }

    @Action({ commit: 'onGetdescargapdf' })
    public async getpdf_dieta(id: number) {
        return await ssmHttpService.get(API.dieta + '/pdf_dieta/' + id);
    }
    @Action({ commit: 'onGetdescargapdf' })
    public async getpdf_lista_compra_dieta(id: number) {
        return await ssmHttpService.get(API.dieta + '/pdf_lista_compra_dieta/' + id);
    }
    @Action({ commit: 'onGetdescargapdf' })
    public async getpdf_Recetas(id: number) {
        return await ssmHttpService.get(API.dieta + '/pdf_recetas/' + id);
    }

    @Action({ commit: 'onGetdescargapdf' })
    public async GetIngredientesRecetasPdf(id: number) {
        return await ssmHttpService.get(API.dieta + '/pdf_ingredientes_recetas/' + id);
    }
    @Action({ commit: 'onGetdescargapdf' })
    public async GetInformeFormato2(id: number) {
        return await ssmHttpService.get(API.dieta + '/pdf_informe_dieta_formato_2/' + id);
    }
    
    @Action({})
    public async SendEmailDieta({ id_dieta: id_dieta, id_paciente: id_paciente }: any) {
        return await ssmHttpService.get(API.dieta + '/send_email_dieta/' + id_dieta + '/' + id_paciente);
    }

    @Action({})
    public async SendEmailListaCompra({ id_dieta: id_dieta, id_paciente: id_paciente }: any) {
        return await ssmHttpService.get(API.dieta + '/send_email_lista_compra/' + id_dieta + '/' + id_paciente);
    }

    @Action({})
    public async SendEmailRecetas({ id_dieta: id_dieta, id_paciente: id_paciente }: any) {
        return await ssmHttpService.get(API.dieta + '/send_recetas/' + id_dieta + '/' + id_paciente);
    }

    @Action({})
    public async SendSoloCantidades({ id_dieta: id_dieta, id_paciente: id_paciente }: any) {
        return await ssmHttpService.get(API.dieta + '/send_ingredientes_recetas/' + id_dieta + '/' + id_paciente);
    }

    @Action({})
    public async SendDietaFormato2({ id_dieta: id_dieta, id_paciente: id_paciente }: any) {
        return await ssmHttpService.get(API.dieta + '/send_informe_dieta_formato_2/' + id_dieta + '/' + id_paciente);
    }

    @Action({ commit: 'onGetdieta_estadisticas' })
    public async getdieta_estadisticas({ id_dieta, id_paciente }: any) {
        if (id_paciente === undefined) {
            id_paciente = 2;
        }

        return await ssmHttpService.post(API.dieta + '/dieta_estadisticas/' + id_dieta + '/' + id_paciente, this.datos_para_estadisticas, false);
    }

    @Action({ commit: 'onGetdieta_Dashboard' })
    public async getdieta_dashboard({ id_dieta, id_paciente }: any) {
        if (id_paciente === undefined) {
            id_paciente = 2;
        }
        return await ssmHttpService.post(API.dieta + '/dieta_dashboard/' + id_dieta + '/' + id_paciente, null, false);
    }

    @Action({})
    public async modificarplato_dieta(visualizacion: dieta_visualizacion) {
        return await ssmHttpService.post(API.dieta + '/set_plato/', visualizacion.toJson(), false);
    }

    @Action({})
    public async equivalenteplato_dieta(visualizacion: dieta_visualizacion) {
        let visual = new dieta_visualizacion(visualizacion);
        return await ssmHttpService.post(API.dieta + '/equivalente/', visual.toJson(), true);
    }

    @Action({})
    public async add_gustosplato_dieta(visualizacion: dieta_visualizacion) {
        let visual = new dieta_visualizacion(visualizacion);
        return await ssmHttpService.post(API.dieta + '/add_gustos/', visual.toJson(), false);
    }

    @Action({})
    public async guardarplato_dieta(visualizacion: dieta_visualizacion) {
        return await ssmHttpService.post(API.dieta + '/add_plato/', visualizacion.toJson(), false);
    }

    @Action({})
    public async eliminarplato_dieta(visualizacion: dieta_visualizacion) {
        return await ssmHttpService.post(API.dieta + '/delete_plato/', visualizacion.toJson(), false);
    }

    @Action({})
    public async Clonar_dieta(id_dieta: number) {
        return await ssmHttpService.post(API.dieta + '/clonar_dieta/' + id_dieta, null, true);
    }

    @Action({})
    public async Asignar_dieta_a({ id_dieta, id_paciente }: any) {
        return await ssmHttpService.post(API.dieta + '/asignar_dieta_a/' + id_dieta + '/' + id_paciente, null, true);
    }

    @Action
    public async guardardieta_visualizacion(dieta_visualizacion: dieta_visualizacion) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.dieta, dieta_visualizacion.toJson());
    }

    @Action
    public async send_dieta_paciente(dieta: dietaDto) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.dieta + '/send_dieta_paciente', dieta.toJson());
    }

    @Action
    public async modificardieta_visualizacion(dieta_visualizacion: dieta_visualizacion) {
        await ssmHttpService.put(API.dieta, dieta_visualizacion + '/' + dieta_visualizacion.id);
    }

    @Action
    public async eliminardieta_visualizacion(id: number) {
        await ssmHttpService.delete(API.dieta + '/' + id, null, false);
    }

    @Action({ commit: 'onGetdieta_visualizacions' })
    public async postremplazarplato({ id_estructura_new, plato }: any) {
        return await ssmHttpService.post(API.dieta + '/replace_plato/' + id_estructura_new, plato);
    }

    @Action({ commit: 'onGetdieta_visualizacions' })
    public async postpegar_comidas_dia({ id_dia, id_comida, plato }: any) {
        return await ssmHttpService.post(API.dieta + '/pegar_todo_dia?id_dia=' + id_dia + '&id_comida=' + id_comida, new BaseDto(plato).toJson());
    }
    
    @Action({ commit: 'onGetdieta_visualizacions' })
    public async postpegar_comida_semana({ id_comida, plato }: any) {
        return await ssmHttpService.post(API.dieta + '/pegar_comida_semana?id_comida=' + id_comida, new BaseDto(plato).toJson());
    }

    @Mutation
    public onGetIdDieta(res: dietaDto) {
        this.datos_dieta = new dietaDto(res);
    }

    @Mutation
    public onGetdietas_paciente(res: dietaAllDto[]) {
        this.dietas = res; //? res.map((x) => new dietaDto(res)) : []
    }

    @Mutation
    public onGetdieta_visualizacions(res: any[][]) {
        this.dieta_visualizacions = res;
    }

    @Mutation
    public onGetdieta_estadisticas(res: dieta_estadistica) {
        this.estadistica = new dieta_estadistica(res);
    }
    
    @Mutation
    public onGetdieta_Dashboard(res: dieta_dashboard) {
        this.dash_dieta = new dieta_dashboard(res);
    }


    @Mutation
    public async onGetdescargapdf(response: any) {
        UtilsFiles.CreateURLFromBase64AndOpen(response, 'application/pdf');
    }

    @Mutation
    public onGetDashHistKcalDieta(res: AltasDepacientes) {
        this.dash_historico = res;
    }


}
export default getModule(dieta_visualizacionModule);